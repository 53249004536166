import enFlag from '../assets/en-flag.jpg'; // Ruta de la bandera de España
import esFlag from '../assets/es-flag.png'; // Ruta de la bandera de EE. UU.
import i18n from 'i18next'; // Asegúrate de importar i18n


const LanguageSwitcher = () => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      };
    
    return (
        <div className="absolute top-4 right-4 flex gap-2">
        <button 
          onClick={() => changeLanguage('es')} 
          className="w-8 h-8 rounded-full overflow-hidden border-2 border-gray-200 hover:border-blue-500 transition-all"
        >
          <img src={esFlag} alt="Español" className="w-full h-full object-cover"/>
        </button>
        <button 
          onClick={() => changeLanguage('en')} 
          className="w-8 h-8 rounded-full overflow-hidden border-2 border-gray-200 hover:border-blue-500 transition-all"
        >
          <img src={enFlag} alt="English" className="w-full h-full object-cover" />
        </button>
      </div>
    )
    }

export default LanguageSwitcher;
