import pixelPhoto from '../assets/pixelPhoto.jpeg'; // Ruta de la foto de perfil

import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();

    const GoDown = () => {
        const element = document.querySelector('.Projects');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <div className="max-w-4xl mx-auto text-center">
          <div className="mb-8">
          <div className="w-40 h-40 mx-auto mb-6 rounded-full overflow-hidden border-4 border-white shadow-lg">
          <img 
            src={pixelPhoto} 
            alt="Profile" 
            className="w-full h-full object-cover" 
            style={{ objectPosition: "center 30%" }} 
          />
        </div>

            <h1 className="text-4xl font-bold text-gray-800 mb-2">Luis Ruiz Nuñez</h1>
            <h2 className="text-xl text-blue-600 mb-4">{t('softwareEngineer')}</h2>
            <div className="flex justify-center gap-4 mb-8">
              <a 
                href="https://www.linkedin.com/in/luis-ruiz-nu%C3%B1ez-b69569185" 
                target="_blank" 
                rel="noreferrer"
                className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-all"
              >
                <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" className="w-6 h-6" />
              </a>
              <a 
                href="https://github.com/LuiRui07" 
                target="_blank" 
                rel="noreferrer"
                className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-all"
              >
                <img src="https://cdn-icons-png.flaticon.com/512/733/733553.png" alt="GitHub" className="w-6 h-6" />
              </a>
            </div>
            <button 
              onClick={GoDown}
              className="px-6 py-3 bg-blue-600 text-white rounded-full font-medium hover:bg-blue-700 transition-colors shadow-md hover:shadow-lg"
            >
              {t('viewMyWork')}
            </button>
          </div>
        </div>
    );
    }

export default Header;
