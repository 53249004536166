import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';

const Projects = () => {
    const { t } = useTranslation();

    return (
        <section className="Projects py-16 px-4 bg-gray-50">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">{t('projects')}</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {[
              {
                icon: <FontAwesomeIcon icon={faAndroid} className="w-6 h-6" />,
                title: t('project1'),
                description: t('project1Description'),
                link: 'https://github.com/LuiRui07/TFG'
              },
              {
                icon: <img src="react-icon.svg" alt="React" className="w-6 h-6" />,
                title: t('project2'),
                description: t('project2Description'),
                link: 'https://github.com/LuiRui07/elRastro'
              },
              {
                icon: <img src="./spring-icon.svg" alt="Spring" className="w-6 h-6" />,
                title: t('project3'),
                description: t('project3Description'),
                link: 'https://github.com/LuiRui07/SkyBank'
              },
              {
                icon: <img src="./swift.svg" alt="Swift" className="w-6 h-6" />,
                title: t('project4'),
                description: t('project4Description'),
                link: 'https://github.com/LuiRui07/iVision'
              }
            ].map((project, index) => (
              <a 
                key={index}
                href={project.link}
                target="_blank"
                rel="noreferrer"
                className="block group"
              >
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                      {project.icon}
                    </div>
                    <h3 className="font-medium text-gray-800 group-hover:text-blue-600 transition-colors">
                      {project.title}
                    </h3>
                  </div>
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>
    );
}

export default Projects;