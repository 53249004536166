// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // pasa i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          welcome: "Welcome to my portfolio! Here, you can explore my latest software projects and learn more about me.",
          softwareEngineer: "Software Engineer",
          viewMyWork: "View My Work",
          projects: "Projects",
          project1: "TFG",
          project1Description: "Description of the first project. My TFG",
          project2: "El Rastro",
          project2Description: "Description of the second project.",
          project3: "SkyBank",
          project3Description: "Description of the third project.",
          project4: "IVision",
          project4Description: "Description of the fourth project.",
          contact: "Contact me:",
          copyright: "© 2024 Luis Ruiz Nuñez",
          myCV: "My CV",
          personalInfo: "Personal Information",
          name: "Name",
          phone: "Phone",
          location: "Location",
          skills: "Skills",
          frontendSkills: "Frontend Development",
          backendSkills: "Backend Development",
          databaseSkills: "Database Management",
          toolsSkills: "Tools",
          experience: "Experience",
          education: "Education",
          degree: "Software Engineering",
          universityName: "University of Málaga",
          dates: "Dates",
          languages: "Languages",
          spanish: "Spanish",
          native: "Native",
          english: "English",
          german: "German",
          basic: "Basic",
          fluent: "Fluent",
          companyName: "Minsait Indra",
          jobDescription: "Platform for managing and optimizing operations in international electric companies, enabling real-time monitoring and efficient data management.",
          jobDescription2: "Job description for second job goes here.",
          june: "June",
          present: "Present",
        },
      },
      es: {
        translation: {
          welcome: "¡Bienvenido a mi portafolio! Aquí puedes explorar mis últimos proyectos de software y aprender más sobre mí.",
          softwareEngineer: "Ingeniero de Software",
          viewMyWork: "Ver Mi Trabajo",
          projects: "Proyectos",
          project1: "Trabajo Fin de Grado",
          project1Description: "Este proyecto propone la implementación de balizas Bluetooth en museos para mejorar la interacción de los visitantes a través de una aplicación Android. Al acercarse a una exposición, los usuarios reciben información contextual relevante en tiempo real, como descripciones y contenidos multimedia. Esto no solo personaliza la experiencia, sino que también fomenta un aprendizaje más dinámico y accesible, transformando la manera en que los visitantes se conectan con el arte y la cultura.",
          project2: "El Rastro",
          project2Description: "Descripción del segundo proyecto.",
          project3: "SkyBank",
          project3Description: "Descripción del tercer proyecto.",
          project4: "IVision",
          project4Description: "Descripción del cuarto proyecto.",
          contact: "Contacto:",
          copyright: "© 2024 Luis Ruiz Nuñez",
          myCV: "Mi CV",
          personalInfo: "Información Personal",
          name: "Nombre",
          phone: "Teléfono",
          location: "Ubicación",
          skills: "Habilidades",
          frontendSkills: "Desarrollo Frontend",
          backendSkills: "Desarrollo Backend",
          databaseSkills: "Gestión de Bases de Datos",
          toolsSkills: "Herramientas",
          experience: "Experiencia",
          education: "Educación",
          degree: "Ingeniería de Software",
          universityName: "Universidad de Málaga",
          dates: "Fechas",
          languages: "Idiomas",
          spanish: "Español",
          native: "Nativo",
          english: "Inglés",
          german: "Alemán",
          basic: "Básico",
          fluent: "Fluido",
          companyName: "Minsait Indra", 
          jobDescription: "Plataforma web para la gestión y optimización de operaciones en empresas eléctricas internacionales, permitiendo el monitoreo en tiempo real y la gestión eficiente de datos.",
          jobDescription2: "Descripción del segundo puesto aquí.",
          june: "Junio",
          present: "Presente",
        },
      },
    },
    lng: "en", // idioma por defecto
    fallbackLng: "en", // idioma a usar si no se encuentra el idioma actual
    interpolation: {
      escapeValue: false, // react ya se encarga de proteger contra XSS
    },
  });

export default i18n;
