import { useTranslation } from 'react-i18next';
import Uma from '../assets/uma.jpg'; // Ruta del logo de la UMA



const Education = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('education')}</h3>
        <div className="border-l-2 border-blue-500 pl-4">
            <div className="flex items-center space-x-4">
            <h4 className="font-medium text-gray-800">{t('universityName')}</h4>
            <img src={Uma} alt="University of Málaga" className="w-10 h-10" />
            </div>
            <p className="text-sm text-gray-600 mb-2">2020 - 2024</p>
            <p className="text-gray-700">{t('degree')}</p>
        </div>
        </div>
    );
    }

export default Education;