import LanguageSwitcher from './sections/languageSwitcher.js';
import Header from './sections/header.js';
import PersonalInfo from './sections/personalInfo.js';
import Skills from './sections/skills.js';
import Education from './sections/education.js';
import Experience from './sections/experience.js';
import Languages from './sections/languages.js';
import Projects from './sections/projects.js';
import Footer from './sections/footer.js';

const App = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Header Section */}
      <header className="relative py-16 px-4 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 text-gray-900">
        <LanguageSwitcher />
        <Header />
      </header>

      {/* Main Content Section */}
      <main className="py-16 px-4 bg-white">
        <div className="max-w-4xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8">
            {/* Left Column */}
            <div className="space-y-8">
              <PersonalInfo />
              <Skills />
            </div>

            {/* Right Column */}
            <div className="space-y-8">
              <Experience />
              <Education />
              <Languages />
            </div>
          </div>
        </div>
      </main>

      {/* Projects Section */}
      <section className="py-16 px-4">
        <Projects />
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;