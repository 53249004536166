import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t } = useTranslation();

    return (
    <footer className="bg-gray-800 text-white py-8 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mb-2">
            {t('contact')}{' '}
            <a href="mailto:luisruiznunez@gmail.com" className="text-blue-400 hover:underline">
              Email
            </a>
          </p>
          <p className="text-gray-400">{t('copyright')}</p>
        </div>
    </footer>
    )
}

export default Footer;