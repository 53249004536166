import { useTranslation } from 'react-i18next';


const Languages = () => {
    const { t } = useTranslation();
    
    return (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('languages')}</h3>
        <div className="space-y-2">
            <div className="flex justify-between items-center">
            <span className="font-medium">{t('spanish')}</span>
            <span className="text-sm text-gray-600">{t('native')}</span>
            </div>
            <div className="flex justify-between items-center">
            <span className="font-medium">{t('english')}</span>
            <span className="text-sm text-gray-600">{t('fluent')}</span>
            </div>
            <div className="flex justify-between items-center">
            <span className="font-medium">{t('german')}</span>
            <span className="text-sm text-gray-600">{t('basic')}</span>
            </div>
        </div>
        </div>
    )
}

export default Languages;