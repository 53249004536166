import { useTranslation } from 'react-i18next';
import Minsait from '../assets/minsait.jpg' // Ruta del logo de Minsait


const Experience = () => {
    const { t } = useTranslation();

    return (
        <div className="space-y-8">
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('experience')}</h3>
                <div className="border-l-2 border-blue-500 pl-4">
                    <div className="flex items-center space-x-4">
                        <h4 className="font-medium text-gray-800">{t('companyName')}</h4>
                        <img src={Minsait} alt="Minsait Indra" className="w-10 h-10" />
                    </div>
                    <p className="text-sm text-gray-600 mb-2">{t('june')} 2024 - {t('present')}</p>
                    <p className="text-gray-700">{t('jobDescription')}</p>
                    <div className="mt-4">
                        <h4 className="text-md font-semibold text-gray-800 mb-2">Tecnologías utilizadas:</h4>
                        <div className="flex flex-wrap gap-2">
                            {[
                                'Java', 'Spring Boot', 'Spring MVC', 'Apache Kafka', 'Git', 'GitLab', 'Maven', 
                                'SQL', 'MongoDB', 'DBeaver', 'AJAX', 'GitHub', 'Spring Framework', 
                                'Bootstrap', 'CSS', 'Subversion', 'Java Servlet'
                            ].map((tech) => (
                                <span key={tech} className="text-gray-800 text-sm font-medium px-3 py-1 border border-gray-300 rounded-full">
                                    {tech}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;