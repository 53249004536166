import { useTranslation } from 'react-i18next';


const PersonalInfo = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('personalInfo')}</h3>
        <ul className="space-y-4">
          <li className="flex flex-col sm:flex-row sm:items-center gap-2">
            <span className="font-medium">Email:</span>
            <a href="mailto:luisruiznunez@gmail.com" className="text-blue-600 hover:underline break-all">
              luisruiznunez@gmail.com
            </a>
          </li>
          <li className="flex flex-col sm:flex-row sm:items-center gap-2">
            <span className="font-medium">{t('phone')}:</span>
            <span>+34 123 456 789</span>
          </li>
          <li className="flex flex-col sm:flex-row sm:items-center gap-2">
            <span className="font-medium">{t('location')}:</span>
            <span>Málaga, España</span>
          </li>
        </ul>
      </div>
    );
    }   

export default PersonalInfo;