import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { t } = useTranslation();

  const skillCategories = [
    {
      title: 'frontendSkills',
      color: 'bg-blue-100 text-blue-800',
      skills: [
        'React', 'Next.js', 'JavaScript', 'TypeScript', 'HTML', 'CSS', 
        'Tailwind CSS', 'Bootstrap', 'Material-UI', 'Redux', 'Styled Components'
      ],
    },
    {
      title: 'backendSkills',
      color: 'bg-green-100 text-green-800',
      skills: [
        'Node.js', 'Express', 'NestJS', 'Spring Boot', 'Spring MVC', 
        'Django', 'Flask', 'Ruby on Rails', 'FastAPI', 'GraphQL', 'REST APIs'
      ],
    },
    {
      title: 'databaseSkills',
      color: 'bg-yellow-100 text-yellow-800',
      skills: [
        'MySQL', 'PostgreSQL', 'MongoDB', 'SQLite', 'MariaDB', 'Firebase', 
        'Redis', 'Cassandra', 'DynamoDB', 'Oracle DB'
      ],
    },
    {
      title: 'toolsSkills',
      color: 'bg-gray-100 text-gray-800',
      skills: [
        'Git', 'GitHub', 'GitLab', 'Bitbucket', 'VS Code', 'IntelliJ', 
        'Eclipse', 'JIRA', 'Notion', 'Figma'
      ],
    },
  ];

  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('skills')}</h3>
      <div className="space-y-6">
        {skillCategories.map(({ title, color, skills }) => (
          <div key={title} aria-label={t(title)}>
            <h4 className="font-medium text-gray-700 mb-2">{t(title)}</h4>
            <div className="flex flex-wrap gap-2">
              {skills.map(skill => (
                <span key={skill} className={`px-3 py-1 ${color} rounded-full text-sm`}>
                  {skill}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;